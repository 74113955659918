<template>
  <p class="field-value readonly">
    <template v-if="listType === 'function'">
      {{ functionValue }}
    </template>
    <template v-else>
      {{ choicesValue }}
    </template>
  </p>
</template>

<script>
  import { mapGetters } from 'vuex';
  import getDropdownData from '@/classes/dropdown-data';
  import IsEmpty from '@/components/Mixins/IsEmpty';

  export default {
    name: 'dropdown-display',
    props: ['value', 'question'],
    mixins: [IsEmpty],
    data() {
      return {
        functionValue: '...',
      };
    },
    computed: {
      listType() {
        if (!this.question) return '';
        return this.question.listSource.startsWith('Function:') ? 'function' : 'choices';
      },
      choicesValue() {
        const list = this.getChoicesList(this.question.listSource);
        if (list.length === 0) return '';
        if (this.isEmpty(this.question.listValueProperty)) {
          const item = list.find((i) => i.choice === this.value);
          return item.niceName || item.choice;
        }
        
        const item = list.find((i) => i[this.question.listValueProperty] === this.value);
        if (this.isEmpty(this.question.listDisplayProperty)) {
          return item.niceName || item.choice;
        }

        return item[this.question.listDisplayProperty];
      },
      ...mapGetters('CachingStore', ['getChoicesList']),
    },
    async mounted() {
      let dropdownList;
      let item;

      if (!this.isEmpty(this.value)) {
        // Function-based values are retrieved from the server, uncached.
        // Choices-based values are retrieved from vuex cache.
        switch (this.listType) {
          case 'function':
            dropdownList = await getDropdownData(
              this.question.listSource,
              this.question.listDisplayProperty,
              this.question.listValueProperty,
            );
            item = dropdownList.find((i) => i.value === this.value);
            if (item == null) this.functionValue = '--';
            this.functionValue = item.displayText;
            break;
          case 'choices':
            this.$store.dispatch('CachingStore/requireChoicesList', this.question.listSource);
            break;
          default:
            break;
        }
      } else {
        this.functionValue = '';
      }
    },
  };
</script>

<style scoped>

</style>
