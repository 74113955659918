<template>
  <div>
    <div @click="toggleCollapse">
      <slot name="header" :collapsed="collapsed"/>
    </div>
    <div v-if="collapsed" @click="toggleCollapse">
      <slot name="collapsed" :collapsed="collapsed"/>
    </div>
    <div v-else>
      <slot name="content"
            :collapsed="collapsed"
            :toggle-collapse="toggleCollapse"
            :collapse-and-scroll="collapseAndScroll"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'collapsible-group',
    props: {
      initiallyCollapsed: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        collapsed: this.initiallyCollapsed,
      };
    },
    methods: {
      toggleCollapse() {
        this.collapsed = !this.collapsed;
      },
      collapseAndScroll(elm) {
        this.collapsed = true;
        console.log('collapseAndScroll');
        if (typeof elm === 'string') {
          let e;
          if (elm.startsWith('#')) {
            e = document.getElementById(elm.substr(1));
          } else {
            e = document.getElementsByClassName(elm);
          }
          if (e) {
            e.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }
      },
    },
  };
</script>
