<template>
  <div v-if="loaded" class="survey-container">
    <collapsible-group :initially-collapsed="true">
      <template v-slot:header="slotProps">
        <h1>
          <icon :name="slotProps.collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"/>
          {{ x('response')}}
        </h1>
      </template>
      <template v-slot:content>
        <div v-for="(questions, groupName) in questionGroups"
             :key="groupName" class="element materialShadow add_entry">

          <collapsible-group :initially-collapsed="true">
            <template v-slot:header>
              <h2 class="groupHeadline">{{groupName}}</h2>
            </template>
            <template v-slot:content>
              <div v-for="question in questions" :key="question.id">
                <p class="field-header readonly">{{ question.name }}:</p>
                <template v-if="question.editorType === 'ok-not-ok-na'">
                  <OkNotOkNADisplay :value="getAnswerWithId(question.id).answer" />
                </template>
                <template v-else-if="question.editorType === 'ok-not-ok'">
                  <OkNotOkDisplay :value="getAnswerWithId(question.id).answer" />
                </template>
                <template v-else-if="question.editorType === 'score'">
                  <ScoreDisplay :value="getAnswerWithId(question.id).answer" />
                </template>
                <template v-else-if="question.editorType === 'dropdown'">
                  <DropdownDisplay :value="getAnswerWithId(question.id).answer" :question="question" />
                </template>
                <template v-else>
                  <p class="field-value readonly">{{ getAnswerWithId(question.id).answer }}</p>
                </template>
                <p class="comment readonly">{{ getAnswerWithId(question.id).comment }}</p>
              </div>
            </template>
          </collapsible-group>
        </div>

        <!-- SUB QUESTIONS -->

        <template v-if="schema.subQuestions.length > 0">
          <div v-for="(answers, unitName) in subAnswers" :key="unitName">
            <collapsible-group :initially-collapsed="true">
              <template v-slot:header="slotProps">
                <h1>
                  <icon class="collapse-button"
                        :name="slotProps.collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"/>
                  {{ unitName }}
                </h1>
              </template>
              <template v-slot:content>
                <div v-for="(questions, groupName) in subQuestionGroups"
                     :key="groupName" class="element materialShadow add_entry">
                  <collapsible-group :initially-collapsed="true">
                    <template v-slot:header="slotProps">
                      <h2 class="groupHeadline">
                        <icon class="collapse-button"
                              :name="slotProps.collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"/>
                        {{ groupName }}
                      </h2>
                    </template>
                    <template v-slot:content>
                      <div v-for="subQuestion in questions" :key="subQuestion.id">
                        <p class="field-header readonly">{{ subQuestion.name }}:</p>
                        <template v-if="subQuestion.editorType === 'ok-not-ok-na'">
                          <OkNotOkNADisplay :value="getAnswerForSubQuestionAndUnit(subQuestion.id, unitName).answer" />
                        </template>
                        <template v-else-if="subQuestion.editorType === 'ok-not-ok'">
                          <OkNotOkDisplay :value="getAnswerForSubQuestionAndUnit(subQuestion.id, unitName).answer" />
                        </template>
                        <template v-else-if="subQuestion.editorType === 'score'">
                          <ScoreDisplay :value="getAnswerForSubQuestionAndUnit(subQuestion.id, unitName).answer" />
                        </template>
                        <template v-else-if="subQuestion.editorType === 'dropdown'">
                          <DropdownDisplay
                            :value="getAnswerForSubQuestionAndUnit(subQuestion.id, unitName)"
                            :question="subQuestion" />
                        </template>
                        <template v-else>
                          <p class="field-value readonly">
                            {{ getAnswerForSubQuestionAndUnit(subQuestion.id, unitName).answer }}
                          </p>
                        </template>
                        <p class="comment readonly">{{
                            getAnswerForSubQuestionAndUnit(subQuestion.id, unitName).comment }}</p>
                      </div>
                    </template>
                  </collapsible-group>
                </div>
              </template>
            </collapsible-group>
          </div>
        </template>
      </template>
    </collapsible-group>
  </div>
</template>

<script>
  import { fromPairs, groupBy, find } from 'lodash';
  import { httpGet } from '@/classes/httpHelper';
  import CollapsibleGroup from '@/components/Survey/CollapsibleGroup';
  import OkNotOkDisplay from '@/components/Survey/DisplayFields/OkNotOkDisplay';
  import OkNotOkNADisplay from '@/components/Survey/DisplayFields/OkNotOkNADisplay';
  import ScoreDisplay from '@/components/Survey/DisplayFields/ScoreDisplay';
  import Answer from '@/types/Answer';
  import DropdownDisplay from '@/components/Survey/DisplayFields/DropdownDisplay';
  import translate from '../Mixins/Translate';
  import Events from '../../enums/event-names';

  /**
   * Display (non-edit) of a survey
   */
  export default {
    name: 'survey-details',
    components: {
      DropdownDisplay,
      ScoreDisplay,
      OkNotOkDisplay,
      OkNotOkNADisplay,
      CollapsibleGroup,
    },
    props: ['visit'],
    mixins: [translate],
    data() {
      return {
        loaded: false,
        schema: null,
        answers: null,
        subAnswers: null,
        collapsed: true,
      };
    },
    computed: {
      questionGroups() {
        if (this.schema == null) return [];
        return groupBy(this.schema.questions, 'groupName');
      },
      subQuestionGroups() {
        if (this.schema == null) return [];
        return groupBy(this.schema.subQuestions, 'groupName');
      },
    },
    async mounted() {
      this.registerEventHandlers();
      await this.loadSurveyData();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
      this.$store.commit('SurveyStore/clearLoadedSurvey');
      this.$store.commit('setTopBarSuffix', '');
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.visitUpdated, this.onVisitUpdated);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.visitUpdated, this.onVisitUpdated);
      },
      async onVisitUpdated(event) {
        if (this.visit.id !== event.id) return;

        await this.loadSurveyData();
      },
      async loadSurveyData() {
        this.loaded = false;
        const details = await httpGet(`surveys/byvisit/${this.visit.id}`);
        this.$store.commit('SurveyStore/setLoadedSurvey', details);
        this.schema = details.schema;
        this.answers = this.parseMainAnswers(details.response);
        this.subAnswers = this.parseSubAnswers(details.response);
        this.loaded = true;
        this.calculateProgress();
      },
      parseMainAnswers(response) {
        if (!response.answers) return [];
        return response.answers
          .filter((a) => a.unitId === '')
          .map((a) => new Answer(a));
      },
      parseSubAnswers(response) {
        if (!response.answers) return [];
        const subAnswers = response.answers.filter((a) => a.unitId !== '');
        const subItemGroups = groupBy(subAnswers, 'unitId');
        const subItemKeys = Object.keys(subItemGroups);
        subItemKeys.forEach((unitId) => {
          const pairs = subItemGroups[unitId].map((item) => [item.surveyQuestionId, item]);
          subItemGroups[unitId] = fromPairs(pairs);
        });
        return subItemGroups;
      },
      getAnswerWithId(id) {
        const answer = this.answers.find((a) => a.surveyQuestionId === id);
        if (!answer) {
          console.warn(`No answer found for id ${id}`);
          return new Answer();
        }
        return answer;
      },
      getAnswerForSubQuestionAndUnit(questionId, unitId) {
        const subAnswer = find(this.subAnswers[unitId], (a) => a.surveyQuestionId === questionId);
        if (!subAnswer) return new Answer();
        return subAnswer;
      },
      calculateProgress() {
        // This might be called before the schema is loaded.
        if (this.schema === undefined || this.schema.questions === undefined) return;

        let questions = 0;
        let answers = 0;
        const questionsToCount = this.schema.questions
          .concat(this.schema.subQuestions)
          .filter((q) => q.includeInProgressCount)
          .map((q) => q.id);
        this.answers.forEach((answer) => {
          if (questionsToCount.indexOf(answer.surveyQuestionId) > -1) {
            questions += 1;
            if (!this.isEmpty(answer.answer)) {
              answers += 1;
            }
          }
        });
        Object.values(this.subAnswers).forEach((subItem) => {
          Object.entries(subItem).forEach(([q, a]) => {
            if (questionsToCount.indexOf(q) > -1) {
              questions += 1;
              if (!this.isEmpty(a.answer)) {
                answers += 1;
              }
            }
          });
        });

        const progress = Math.round((answers / questions) * 100);
        this.$store.commit('setTopBarSuffix', `(${progress}%)`);
        console.log(progress);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "survey-form";

  .comment {
    font-style: italic;
    margin-bottom: 3px;
    font-size: 11px;
  }
</style>
