import fillTemplate from 'es6-dynamic-template';
import { httpGet } from '@/classes/httpHelper';

const endpoints = {
  users: 'users/public',
};

/**
 * Retrieves a list of dropdown data from the server. Works with both Function and Choices.
 * @param {string} listSource The type of data to retrieve
 * @param {string} displayProperty The property to use for display. Can be a template string.
 * @param {string} valueProperty The property to use for value.
 * @returns {Promise<[]>}
 */
export default async function getDropdownData(listSource, displayProperty, valueProperty) {
  const dropdownData = [];
  if (listSource.startsWith('Function:')) {
    const source = listSource.replace('Function: ', '').trim().toLowerCase();
    const endpoint = endpoints[source];
    console.log('dropdown data endpoint: ', endpoint);
    if (!endpoint) return dropdownData;
    const list = await httpGet(endpoint);
    if (!list) return dropdownData;
    list.sort((a, b) => a.sortIndex - b.sortIndex);

    // Changes the template from 'bla bla {{name}} bla' to 'bla bla ${name} bla'
    const template = displayProperty
      .replace(/\{\{/g, '${')
      .replace(/\}\}/g, '}');

    for (const item of list) {
      dropdownData.push({
        value: item[valueProperty],
        displayText: fillTemplate(template, item),
      });
    }
  } else {
    // TODO: Refactor to use the Choices cache from CachingStore
    // I.e. not retrieving a list to return here, but calling the requireChoicesList action
    // in CachingStore and then using the getter for getting the values from CachingStore.
    const list = await httpGet(`choices/${listSource}`);
    if (!list) return dropdownData;
    list.sort((a, b) => a.sortIndex - b.sortIndex);

    for (const item of list) {
      const value = valueProperty != null && valueProperty !== '' ? item[valueProperty] : item.choice;
      const displayText = displayProperty != null && displayProperty !== '' ? item[displayProperty] : item.niceName;
      dropdownData.push({
        value,
        displayText,
      });
    }
  }
  return dropdownData;
}
