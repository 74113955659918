<template>
  <div>
    <div class="field_value">
      <span :class="['ok', { selected: value === 'ok' }]">
        <i class="fa fa-smile-o"></i>
      </span>
      <span :class="['not-ok', { selected: value === 'not ok' }]">
        <i class="fa fa-exclamation-circle"></i>
      </span>
      <span :class="['na', { selected: value === 'na' }]">
        <i class="fa fa-times"></i>
      </span>
      <div class="lsc-clear"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ok-not-ok-na-display',
    props: ['value'],
  };
</script>

<style scoped lang="scss">
.field_value {
  margin-bottom: 15px;
}

span {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
  color: #ababab;
  border: 1px dashed #ababab;
  width: calc(33% - 10px);
  padding: 10px;
  text-align: center;
  cursor: pointer;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: #fafafa;

  i {
    font-size: 32px;
  }

  &.selected {
    border: 0;
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);

    &.ok {
      background: var(--ThemeSurveyOk3Button);
      color: var(--ThemeSurveyOk3ButtonText);
    }

    &.not-ok {
      background: var(--ThemeSurveyNotOk3Button);
      color: var(--ThemeSurveyNotOk3ButtonText);
    }

    &.na {
      background: var(--ThemeSurveyNA3Button);
      color: var(--ThemeSurveyNA3ButtonText);
    }
  }
}

span:last-of-type {
  margin-right: 0 !important;
}

</style>
