<template>
  <div>
    <div class="field_value">
      <span :class="['green', { selected: value === 'green' }]">
        <icon name="fa-smile-o"/>
      </span>
      <span :class="['yellow', { selected: value === 'yellow' }]">
        <icon name="fa-meh-o"/>
      </span>
      <span :class="['red', { selected: value === 'red' }]">
        <icon name="fa-warning"/>
      </span>
      <div class="lsc-clear"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'score-display',
    props: ['value'],
  };
</script>

<style scoped lang="scss">
.field_value {
  margin-bottom: 15px;
}

span {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
  color: #ababab;
  border: 1px dashed #ababab;
  width: calc(33% - 10px);
  padding: 10px;
  text-align: center;
  cursor: pointer;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: #fafafa;

  i {
    font-size: 32px;
  }

  &.selected {
    border: 0;
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);

    &.green {
      background: var(--ThemeVisitScoreGreen);
      color: var(--ThemeVisitScoreGreenText);
    }

    &.yellow {
      background: var(--ThemeVisitScoreYellow);
      color: var(--ThemeVisitScoreYellowText);
    }

    &.red {
      background: var(--ThemeVisitScoreRed);
      color: var(--ThemeVisitScoreRedText);
    }
  }
}

span:last-of-type {
  margin-right: 0 !important;
}

</style>
