/**
 * Describes an answer in a survey, consisting of two values:
 * The actual answer and an optional comment.
 */
export default class Answer {
  answer = '';

  comment = '';

  hasError = false;

  constructor(obj) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  clone() {
    return new Answer(this);
  }
}
